@tailwind base;
@tailwind components;
@tailwind utilities;


html, body, #root {
    height: 100%;
}

.MuiDialog-paper {
    overflow: visible !important;
}